<template>
    <div class="home">
        <panel class="hello text-center">
            <img :src="require('@/assets/logo.png')" height="300" width="300" alt="美科">
            <h1 class="company">美 科 教 育</h1>
            <p>为学校和教育机构提供一站式少儿编程教育解决方案</p>
        </panel>

        <panel class="problem" light>
            <h1 class="title text-center">我们看见，很多教育机构正面临以下难题</h1>
            <b-row class="content">
                <b-col lg="5" class="item">
                    <img src="https://static.makerfac.com/w/p1.png" class="icon">
                    <div>
                        <h2>增长难</h2>
                        <span>线下租金昂贵，装修成本高，受疫情影响生意骤停</span>
                    </div>
                </b-col>
                <b-col lg="5" offset-lg="2" class="item right">
                    <img src="https://static.makerfac.com/w/p2.png" class="icon">
                    <div>
                        <h2 class="text-info-light">管理难</h2>
                        <span>人工排班排课，通知学员，经常遗漏、出错;各类系统数据信息不通，操作繁琐，效率低;教学成果难以沉淀传播</span>
                    </div>
                </b-col>
                <b-col lg="5" class="item">
                    <img src="https://static.makerfac.com/w/p3.png" class="icon">
                    <div>
                        <h2 class="text-success">招生难</h2>
                        <span>受地域限制，招生范围有限，地推、广告成本高，促销玩法单一，年轻人无感</span>
                    </div>
                </b-col>
                <b-col lg="5" offset-lg="2" class="item right">
                    <img src="https://static.makerfac.com/w/p4.png" class="icon">
                    <div>
                        <h2 class="text-warning">开发难</h2>
                        <span>自主开发周期长、风险高，外包团队花费贵、反复沟通，进度难以把控</span>
                    </div>
                </b-col>
                <b-col lg="5" class="item">
                    <img src="https://static.makerfac.com/w/p5.png" class="icon">
                    <div>
                        <h2 style="color: #f1887a">直播难</h2>
                        <span>直播软件操作复杂，经常卡顿崩溃，学员互动难、老师讲课难</span>
                    </div>
                </b-col>
                <b-col lg="5" offset-lg="2" class="item right">
                    <img src="https://static.makerfac.com/w/p6.png" class="icon">
                    <div>
                        <h2 class="text-danger">软件选择难，"一步到位"难</h2>
                        <span>大部分软件无法同时满足所有需求，同时购买上线好几个系统，花费大，管理成本高</span>
                    </div>
                </b-col>
            </b-row>
        </panel>
        <panel class="text-center pickup" light>
            <h1 class="title">通过美科教育您将获得获得</h1>
            <b-row class="content">
                <b-col lg="3" md="6">
                    <img src="https://static.makerfac.com/w/pi1.png">
                    <h2>系列课程</h2>
                    <p>科学全面课程体系</p>
                    <p>4P教学理念</p>
                    <p>项目制软硬件协同教学</p>
                    <p>教学资源与教学课件</p>
                </b-col>
                <b-col lg="3" md="6">
                    <img src="https://static.makerfac.com/w/pi2.png">
                    <h2 class="text-info-light">教师培训</h2>
                    <p>少儿编程行业培训</p>
                    <p>校长运营培训</p>
                    <p>课程顾问招生话术培训</p>
                    <p>老师教学、编程知识培训</p>
                </b-col>
                <b-col lg="3" md="6">
                    <img src="https://static.makerfac.com/w/pi3.png">
                    <h2>管理平台</h2>
                    <p>教务管理、教学平台</p>
                    <p>各类编程工具</p>
                    <p>创作分享社区</p>
                    <p>招生推广营销工具</p>
                </b-col>
                <b-col lg="3" md="6">
                    <img src="https://static.makerfac.com/w/pi4.png">
                    <h2 class="text-info-light">运营服务</h2>
                    <p>全流程落地运营指导</p>
                    <p>提供招生物料</p>
                    <p>教师、学生考级</p>
                    <p>竞赛、游学体系</p>
                </b-col>
            </b-row>
        </panel>

        <about short/>

        <course short light/>

        <platform short/>

        <grade-examination short/>

        <contact-us/>
    </div>
</template>

<script>
import Panel from "@/components/Panel";
import About from "@/page/about";
import Course from "@/page/course";
import Platform from "@/page/platform";
import GradeExamination from "@/page/grade/index";
import ContactUs from "@/page/about/ContactUs";

export default {
    name: "index",
    components: {ContactUs, About, Course, Platform, GradeExamination, Panel},
    metaInfo: {
        title: '【官网】美科教育少儿编程教育-STEAM教育-编程教育-创客教育-编程竞赛-教育服务品牌-美科编程',
        meta: [
            {
                name: 'keywords',
                content: '编程,美科,教育,少儿编程,美科编程,编程竞赛,STEAM,创客教育,机器人教育,教育培训'
            },
            {
                name: 'description',
                content: '【商务咨询:business@makerfac.com】美科教育专注打造一站式教培机构少儿编程教育,STEM教育,创客教育,编程竞赛的行业解决方案,快速复制办学服务能力。'
            }
        ]
    }
}
</script>

<style lang="scss" scoped>
.home {

    .company {
        font-size: 7rem;
        font-weight: bold;
    }

    .problem {
        background: url("https://static.makerfac.com/w/p0.png") center bottom no-repeat white;
        background-size: 700px;

        .content {

            .item {
                display: flex;
                margin-top: 6rem;

                .icon {
                    height: 100px;
                    width: 100px;
                    margin: 0 20px;
                }

                h2 {
                    font-weight: bold;
                }

                span, p {
                    color: #6c757d;
                }
            }

            .item.right {
                flex-direction: row-reverse;
                text-align: right;
            }

            @media (max-width: 992px) {
                .item.right {
                    flex-direction: row;
                    text-align: left;
                }
            }
        }
    }

    .pickup {
        .content {
            img {
                height: 229px;
                width: 229px;
            }

            h2 {
                font-size: 2.5rem;
                font-weight: bold;
                margin-bottom: 2rem;
            }

            p {
                color: #6c757d;
            }
        }
    }
}
</style>