<template>
    <div class="platform">
        <panel class="text-center" v-if="!short">
            <h1 class="title">
                美科编程社区<br/>
                <small>人工智能时代，学习编程，培养小朋友想象力、创造力、逻辑思维能力</small>
            </h1>
            <a href="https://community.makerfac.com/" target="_blank" class="btn btn-outline-warning btn-lg">
                立即前往<b-icon-box-arrow-up-right class="p-1"/>
            </a>
        </panel>

        <panel light class="president">
            <h1 class="text-center title">
                平台介绍<br/>
                <small>Platform Introduction</small>
            </h1>
            <h1 class="title text-center text-info-light">校长的教务管理平台</h1>
            <b-row class="content">
                <b-col lg="7">
                    <div class="item">
                        <img src="https://static.makerfac.com/w/pl1.png">
                        <div>
                            <h2>教师管理</h2>
                            <p>教学动态一览即知、便捷设置教师权限，随时把控教学进度</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="https://static.makerfac.com/w/pl2.png">
                        <div>
                            <h2>学生管理</h2>
                            <p>学生账号批量注册，调班管理操作便捷</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="https://static.makerfac.com/w/pl3.png">
                        <div>
                            <h2>动态监控</h2>
                            <p>轻松掌握教师、学生状态，全面掌控校区学情</p>
                        </div>
                    </div>
                </b-col>
                <img src="https://static.makerfac.com/w/pl0.png" class="col-lg-5">
            </b-row>
        </panel>

        <panel light class="teacher">
            <h1 class="title text-center text-info-light">老师的教学神器</h1>
            <b-row class="content">
                <b-col lg="7">
                    <div class="item">
                        <img src="https://static.makerfac.com/w/plt1.png">
                        <div>
                            <h2>课前</h2>
                            <p>课程体系划分明确，教学规划一步搞定</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="https://static.makerfac.com/w/plt2.png">
                        <div>
                            <h2>课中</h2>
                            <p>课程内容优质丰富，教案课件轻松教学</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="https://static.makerfac.com/w/plt3.png">
                        <div>
                            <h2>课后</h2>
                            <p>课后作业线上点评，班级学情智能反馈</p>
                        </div>
                    </div>
                </b-col>
                <img src="https://static.makerfac.com/w/plt0.png" class="col-lg-5">
            </b-row>
        </panel>

        <panel light class="student">
            <h1 class="title text-center text-success">学生的编程学习乐园</h1>
            <b-row class="content">
                <b-col lg="7">
                    <div class="item">
                        <img src="https://static.makerfac.com/w/pls1.png">
                        <div>
                            <h2>简单的操作体验</h2>
                            <p>进入课堂只需要一步，孩子轻松学习</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="https://static.makerfac.com/w/pls2.png">
                        <div>
                            <h2>专业的编程工具</h2>
                            <p>基于课程的编程工具，项目制软硬协同教学</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="https://static.makerfac.com/w/pls3.png">
                        <div>
                            <h2>无限的创意作品</h2>
                            <p>建立专属素材库，个人作品中心，分享无限创意</p>
                        </div>
                    </div>
                </b-col>
                <img src="https://static.makerfac.com/w/pls0.png" class="col-lg-5" height="100%">
            </b-row>
        </panel>

        <panel>
            <h1 class="text-center title">
                精选作品<br/>
                <small>Featured works</small>
            </h1>

            <div class="content">

                <img src="https://static.makerfac.com/w/works.png">

                <a href="https://community.makerfac.com/" target="_blank" class="btn btn-outline-warning float-right m-3">
                    查看更多作品<b-icon-box-arrow-up-right class="p-1"/>
                </a>
            </div>
        </panel>
    </div>
</template>

<script>
import Panel from "@/components/Panel";
export default {
    name: "index",
    components: {Panel},
    props: {
        short: Boolean
    },
    metaInfo: {
        title: '平台介绍-【官网】美科教育少儿编程教育-STEAM教育-编程教育-创客教育-编程竞赛-教育服务品牌-美科编程',
        meta: [
            {
                name: 'keywords',
                content: '编程,美科,教育,少儿编程,美科编程,编程竞赛,STEAM,创客教育,机器人教育,教育培训'
            },
            {
                name: 'description',
                content: '【商务咨询:business@makerfac.com】美科教育专注打造一站式教培机构少儿编程教育,STEM教育,创客教育,编程竞赛的行业解决方案,快速复制办学服务能力。'
            }
        ]
    }
}
</script>

<style lang="scss" scoped>
.platform {
    .content {
        padding: 0 15%;
        .item {
            display: flex;
            color: #6c757d;
            margin-bottom: 3rem;
            h2 {
                font-weight: bold;
            }

            div {
                position: relative;
                top: 2rem;
            }
        }
    }
}
</style>